<template>
  <div>
    <!-- <div>
      <p>{{ this.$route.params.id }}</p>
    </div> -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12">
        <div>
          <div class="row justify-content-between">
            <div class="col-4 mt-1">
              <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'deterioration' })"
                >กลับ</b-button
              >
            </div>
            <div class="col-4 mt-1" style="text-align: center">
              <div class="title head">
                <p class="h2" style="color: #558cef">ประมวลผลค่าเสื่อมประจำปี</p>
              </div>
            </div>
            <div class="col-4" style="text-align: end">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-button variant="outline-primary"
                    ><feather-icon icon="ShareIcon" class="mr-50" />Export
                    <feather-icon icon="ChevronDownIcon" class="mr-50"
                  /></b-button>
                </template>
                <b-dropdown-item>
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  <feather-icon icon="FileIcon" class="mr-50" />
                  <span>Excel</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="ClipboardIcon" class="mr-50" />
                  <span>Pdf</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <hr />
          <!-- search input -->
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.budget_year }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.target_date }}</div>
          </div>
          <!-- <div class="row">
            <div class="ml-1 h4 text-primary" text="primary">
              กลุ่มทรัพย์สิน:
            </div>
            <div class="ml-1 h4">ทรัพย์สินสำนักงาน</div>
            <div class="ml-1 h4 text-primary" text="primary">
              ชื่อทรัพย์สิน:
            </div>
            <div class="ml-1 h4">เครื่องบันทึกเวลาเข้า-ออก</div>
          </div> -->
          <!-- <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">
              กลุ่มงานผู้ขอเบิก/ผู้ขอเบิก :
            </div>
            <div class="ml-1 h4">กลุ่มผู้อำนวยการ</div>
            <div class="ml-1 h4 text-primary">ผู้เบิก :</div>
            <div class="ml-1 h4">นายหนึ่งสอง สามสี่ห้า</div>
          </div> -->

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>
                <span v-else-if="props.column.field === 'อัตตราค่าเสื่อมร้อยละ'">
                  <div class="text-right">
                    {{ props.row.อัตตราค่าเสื่อมร้อยละ }}
                  </div>
                </span>
                <span v-else-if="props.column.field === 'ค่าเสื่อมประจำปี'">
                  <div class="text-right">{{ props.row.ค่าเสื่อมประจำปี }}</div>
                </span>
                <span v-else-if="props.column.field === 'ค่าเสื่อมราคาสะสม'">
                  <div class="text-right">{{ props.row.ค่าเสื่อมราคาสะสม }}</div>
                </span>
                <span v-else-if="props.column.field === 'มูลค่าสุทธิ'">
                  <div class="text-right">{{ props.row.มูลค่าสุทธิ }}</div>
                </span>
                <span v-else-if="props.column.field === 'จำนวน'">
                  <div class="text-right">{{ props.row.จำนวน }}</div>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange1($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { BButton } from 'bootstrap-vue'
import { BFormFile } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import { BFormDatepicker } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import API from '@/views/connectDB/condb.js'
import { saveExcel } from '@progress/kendo-vue-excel-export'

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      show: false,
      pageLength: 100,
      currentPage: 1,

      dir: false,
      total: '',
      columns: [
        {
          label: 'วันเดือนปี',
          field: 'admitdate',
          width: '150px',
        },
        {
          label: 'หมายเลขทรัพย์สิน',
          field: 'e_number',
          width: '160px',
        },
        {
          label: 'Serial Number',
          field: 'searial number',
          width: '150px',
        },
        {
          label: 'รายละเอียด',
          field: 'e_name',
          width: '300px',
        },
        {
          label: 'จำนวน',
          field: 'amount',
        },
        {
          label: 'หน่วยนับ',
          field: 'unittype',
          width: '100px',
        },
        {
          label: 'ราคาต่อหน่วย + vat 7%',
          field: 'unitprice_vat',
          width: '200px',
          type: 'number',
           sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: 'อายุการใช้งาน',
          field: 'e_lifetime',
          width: '150px',
        },
        {
          label: 'อัตตราค่าเสื่อมร้อยละ',
          field: 'e_depreciation_year',
          width: '180px',
        },
        {
          label: 'ค่าเสื่อมประจำปี',
          field: 'daycount',
          width: '150px',
        },
        {
          label: 'ค่าเสื่อมราคาสะสม',
          field: 'annualdepreciation',
          width: '180px',
          type: 'number',
           sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: 'มูลค่าสุทธิ',
          field: 'netprice',
          width: '120px',
          type: 'number',
           sortable: true,
          formatFn: this.formatFn,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.get_data()
  },
  methods: {
     formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'ประมวลผลค่าเสื่อมประจำปี',
        columns: [
          {
            title: 'ประมวลผลค่าเสื่อมประจำปี',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'วันเดือนปี',
                field: 'admitdate',
                width: '150px',
              },
              {
                title: 'หมายเลขทรัพย์สิน',
                field: 'e_number',
                width: '160px',
              },
              {
                title: 'Serial Number',
                field: 'searial number',
                width: '150px',
              },
              {
                title: 'รายละเอียด',
                field: 'e_name',
                width: '300px',
              },
              {
                title: 'จำนวน',
                field: 'amount',
              },
              {
                title: 'หน่วยนับ',
                field: 'unittype',
                width: '100px',
              },
              {
                title: 'ราคาต่อหน่วย + vat 7%',
                field: 'unitprice_vat',
                width: '200px',
                type: 'number',
              },
              {
                title: 'อายุการใช้งาน',
                field: 'e_lifetime',
                width: '150px',
              },
              {
                title: 'อัตตราค่าเสื่อมร้อยละ',
                field: 'e_depreciation_year',
                width: '180px',
              },
              {
                title: 'ค่าเสื่อมประจำปี',
                field: 'daycount',
                width: '150px',
              },
              {
                title: 'ค่าเสื่อมราคาสะสม',
                field: 'annualdepreciation',
                width: '180px',
                type: 'number',
              },
              {
                title: 'มูลค่าสุทธิ',
                field: 'netprice',
                width: '120px',
                type: 'number',
              },
            ],
          },
        ],
      })
    },
    handlePagechange1(event) {
      this.currentPage = event
      this.get_data()
    },
    handleChangePage1(event) {
      this.pageLength = event
      this.get_data()
    },
    async get_data() {
      this.show = true
      const { access_token } = await this.access_token()
      const url = `${API}processAnnualDepreciation?date=${this.$route.params.id.target_date}&_page=${this.currentPage}&_limit=${this.pageLength}`
      const head = {
        headers: {
          Authorization: await access_token,
        },
      }
      const res = await axios.get(url, head)
      this.rows = res.data.message.data
      this.total = res.data.message.total
      this.show = false
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card {
  padding: 1rem;
}
.mid {
  padding: 5px;
}
</style>
